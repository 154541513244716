// React Imports
import React, {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import _take from 'lodash/take';
import _orderBy from 'lodash/orderBy';
import YouTube from 'react-youtube';
// Components
import HomeNews from '../news/home_news/home_news';
import Shows from '../shows/shows';
import Band from '../band/band';
import Booking from '../booking/booking';
import SocialMediaFooter from '../social_media/social_media_footer/social_media_footer';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
// Styling
import './containers.scss';
import '../video/video.scss';

function Containers() {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('videos');

        ref.once('value').then(data => {
            setVideos(data.val());
        });
    }, []);

    const opts = {
        height: '100%',
        width: '100%',
    };

    function renderHomeVideo(videos) {
        return _take(_orderBy(videos, 'date', ['desc']), 1).map(video => (
            <div key={video.id} className="home-youtube-container">
                <YouTube videoId={video.youtubeId}
                         opts={opts}
                         className="home-youtube-video" />
                <div className="video-loader">
                    <FontAwesomeIcon icon={faSpinner} spin pulse size="3x" />
                </div>
            </div>
        ))
    }

    return (
        <main className="main-view">
            <section id="home" className="fixed-bg bg-quote1">
                {renderHomeVideo(videos)}
            </section>
            <HomeNews />
            <section className="fixed-bg bg-quote2">
                <h1 className="quote">...the hottest New Act from Boston...BLEW US AWAY!"<em className="quote-author">- Matterhorn, Sunday River</em></h1>
            </section>
            <Shows />
            <section className="fixed-bg bg-quote3">
                <h1 className="quote">"...Bostonians, the band Kilroe is here to save our nights out..."<em className="quote-author">- Examiner.com</em></h1>
            </section>
            <Band />
            <section className="fixed-bg bg-quote4">
                <h1 className="quote">"...really know how to keep the crowd going...an amazing show!..."<em className="quote-author">- Hennessy's</em></h1>
            </section>
            <Booking />
            <section className="fixed-bg bg-quote5">
                <h1 className="quote">"...best band we've had all summer!"<em className="quote-author">- One Pelham East</em></h1>
            </section>
            <SocialMediaFooter />
        </main>
    );
}

export default Containers;
