// React Imports
import React from 'react';
import {NavHashLink as NavLink} from 'react-router-hash-link';
// Components
import Headroom from 'react-headroom';
import MainMenu from './main_menu/main_menu';
import MobileMenu from './mobile_menu/mobile_menu';
// Styling
import './header.scss';

function Header() {
    return (
        <Headroom>
            <header className="header">
                <div className="logo">
                    <NavLink to="/#home"
                             smooth
                             className="ga-home">Kilroe
                    </NavLink>
                </div>
                <MainMenu />
                <MobileMenu />
            </header>
        </Headroom>
    );
}

export default Header;
