// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import YouTube from 'react-youtube';
// Components
import SocialMediaFooter from '../social_media/social_media_footer/social_media_footer';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faVideo} from '@fortawesome/free-solid-svg-icons';
// Styling
import './video.scss';

function Video() {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('videos');

        ref.once('value').then(data => {
                setVideos(data.val());
            });
    }, []);

    const opts = {
        height: '100%',
        width: '100%',
    };

    function renderVideos(videos) {
        return videos.map(video => (
            <div key={video.id} className="youtube-container">
                <YouTube videoId={video.youtubeId}
                         opts={opts}
                         className="youtube-video" />
                <div className="video-loader">
                    <FontAwesomeIcon icon={faSpinner} spin pulse size="3x" />
                </div>
            </div>
        ))
    }

    return (
        <section id="video">
            <section className="video-container">
                <div className="youtube-container">
                    <h1 className="section-header-video">
                        <FontAwesomeIcon icon={faVideo} size="lg" className="section-header-icon" />Video
                    </h1>
                    {renderVideos(videos)}
                </div>
            </section>

            <SocialMediaFooter />
        </section>
    );
}

export default Video;
