// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import _orderBy from 'lodash/orderBy';
import Moment from 'react-moment';
import moment from 'moment';
// Bootstrap Components
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
// Styling
import './shows.scss';

function Shows() {
    const [shows, setShows] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('shows');

        ref.once('value').then(data => {
            setShows(data.val());
        });
    }, []);

    moment.suppressDeprecationWarnings = true;

    /*function renderShows(shows) {
        return _orderBy(shows, 'date', ['asc']).filter(function (show) {
            return moment(show.date, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'));
        }).map(show => (
            <ul key={show.id} className="shows-list">
                <li className="shows-list-item shows-large">
                    <Moment format="ddd M.D.YYYY">
                        {show.date}
                    </Moment>
                </li>
                <li className="shows-list-item">{show.venue + ' - ' + show.location}</li>
                <li className="shows-list-item">{show.time}</li>
            </ul>
        ))
    }*/

    function renderPastShows(shows) {
        return _orderBy(shows, 'date', ['desc']).filter(function (show) {
            return moment(show.date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'));
        }).map(show => (
            <ul key={show.id}>
                <li className="shows-list-item shows-past-list-item">
                    <Moment format="M.D.YY">{show.date}</Moment>{' - ' + show.venue + ' - ' + show.location}
                </li>
            </ul>
        ))
    }

    const [toggleText, setToggleText] = useState(true);

    function toggleButtonText() {
        setToggleText(!toggleText);
    }

    return (
        <section className="scrolling-bg show-bg shows-container" id="shows">
            <article className="container">
                <h1 className="section-header">
                    <FontAwesomeIcon icon={faCalendarAlt} className="section-header-icon"/>Shows
                </h1>
                <ul className="shows-header shows-list">
                    <li className="shows-list-item">Date</li>
                    <li className="shows-list-item">Venue/Location</li>
                    <li className="shows-list-item">Time</li>
                </ul>
                <div className="shows-list-empty">Sorry, no new dates scheduled. Check back soon!</div>
                {/*{renderShows(shows)}*/}
                <Accordion>
                    <Card>
                        <Card.Header>
                            <div className="toggle-shows ga-past-shows">
                                <Accordion.Toggle onClick={toggleButtonText} variant="link" eventKey="0" className="btn btn-main">
                                    <div className="toggle-shows ga-past-shows">
                                        <FontAwesomeIcon icon={faCalendarAlt} className="main-menu-icon"/>
                                        {toggleText ? 'Past Shows' : 'Hide Past Shows'}
                                    </div>
                                </Accordion.Toggle>
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="shows-past-container">
                                    <div className="shows-past">
                                        {renderPastShows(shows)}
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </article>
        </section>
    );
}

export default Shows;
