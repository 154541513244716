// React Imports
import React from 'react';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowAltCircleUp} from '@fortawesome/free-solid-svg-icons'
// Styling
import './scroll_to_top.scss';

function ScrollToTop() {
    window.addEventListener('scroll', function() {
        const scrollTopWrapper = document.querySelector('.scroll-top-wrapper');

        scrollTopWrapper.classList.toggle('show', this.pageYOffset > 300);
    });

    const scrollToTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    };

    return (
        <div className="scroll-top-wrapper" onClick={scrollToTop}>
            <span className="scroll-top-inner">
            <FontAwesomeIcon icon={faArrowAltCircleUp} size="2x" className="scroll-top-icon" />
            </span>
        </div>
    )
}

export default ScrollToTop;
