import React, {useEffect, useState} from 'react';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import Sidebar from 'react-sidebar';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faNewspaper} from '@fortawesome/free-regular-svg-icons';
import {faBars, faCameraRetro, faHeadphones, faMobileAlt, faStar, faVideo} from '@fortawesome/free-solid-svg-icons'
// Styling
import './mobile_menu.scss';
import Scrollspy from "react-scrollspy";

const MobileMenu = () => {
    useEffect(() => {
        const overlay = document.querySelector('.mobile-menu div');

        overlay.addEventListener('click', function() {
                const headroom = document.querySelector('.headroom');
                const mobileMenu = document.querySelector('.mobile-menu');

                headroom.classList.remove('full-height');
                mobileMenu.classList.remove('show-menu');
            });
    }, []);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const addMobileMenuStyles = () => {
        setSidebarOpen(true);

        const headroom = document.querySelector('.headroom');
        const mobileMenu = document.querySelector('.mobile-menu');

        headroom.classList.add('full-height');
        mobileMenu.classList.add('show-menu');
    };

    return (
      <nav className="mobile-menu">
        <Sidebar
          sidebar={
            <ul className="mobile-menu-link-list">
                <Scrollspy items={['home', 'news', 'shows', 'band', 'video', 'photos', 'booking']} currentClassName="mobile-scrollspy-hover">
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/#home"
                             smooth
                             activeClassName=""
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faHeadphones} className="main-menu-icon" />Home
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/#news"
                             smooth
                             activeClassName=""
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faNewspaper} className="main-menu-icon" />News
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/#shows"
                             smooth
                             activeClassName=""
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faCalendarAlt} className="main-menu-icon" />Shows
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/#band"
                             smooth
                             activeClassName=""
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faStar} className="main-menu-icon" />Band
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/video"
                             smooth
                             activeClassName="active"
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faVideo} className="main-menu-icon" />Video
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/photos"
                             smooth
                             activeClassName="active"
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faCameraRetro} className="main-menu-icon" />Photos
                    </NavLink>
                </li>
                <li className="mobile-menu-link-list-item">
                    <NavLink to="/#booking"
                             smooth
                             activeClassName=""
                             onClick={() => setSidebarOpen(false)}
                             className="ga-home mobile-menu-link">
                        <FontAwesomeIcon icon={faMobileAlt} className="main-menu-icon" />Booking
                    </NavLink>
                </li>
                </Scrollspy>
            </ul>
          }
          open={sidebarOpen}
          onSetOpen={setSidebarOpen}
          styles={{root: {zIndex: '200'}, overlay: {backgroundColor: 'rgba(0,0,0,.8)'}}}
        >
            <button onClick={() => addMobileMenuStyles()} className="mobile-menu-button">
              <FontAwesomeIcon icon={faBars} size="3x" />
            </button>
        </Sidebar>
      </nav>
    );
};

export default MobileMenu;
