// React Imports
import React, {useState, useCallback, useEffect} from 'react';
import firebase from 'firebase/app';
import Gallery from 'react-photo-gallery';
import Carousel, {Modal, ModalGateway} from 'react-images';
import _filter from "lodash/filter";
// Components
import SocialMediaFooter from '../social_media/social_media_footer/social_media_footer';
// Bootstrap Components
import {DropdownButton, Dropdown} from 'react-bootstrap';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera, faSpinner} from '@fortawesome/free-solid-svg-icons';
// Styling
import './photos.scss';

function Photos() {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('photos');

        ref.once('value').then(data => {
            setPhotos(data.val());
        });
    }, []);

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, {photo, index}) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const [dateValue, setDateValue] = useState('9-29-2018');
    const photosFiltered = _filter(photos, {'date': dateValue});

    function renderPhotoGallery(photos) {
        return (
            <>
                <Gallery photos={photosFiltered} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photosFiltered}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </>
        )
    }

    return (
        <section id="photos">
            <section className="photos-container">
                <h1 className="section-header-photos">
                    <FontAwesomeIcon icon={faCamera}
                                     size="lg"
                                     className="section-header-icon" />Photos
                </h1>
                <div className="photo-button-group">
                    <DropdownButton id="dropdown-basic-button" title="2017-2018" className="select-photos">
                        <Dropdown.Item onClick={() => setDateValue('11-29-2018')}
                                       className="photo-button">Fed Up With Cancer Benefit 11/29/2018
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('9-29-2018')}
                                       className="photo-button">Harpoon Octoberfest 9/29/2018
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('8-5-2017')}
                                       className="photo-button">Lawn On D 8/5/2017
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton id="dropdown-basic-button" title="2010-2011" className="select-photos">
                        <Dropdown.Item onClick={() => setDateValue('6-10-2011')}
                                       className="photo-button">BSSC Cruise 6/10/2011
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('5-7-2010')}
                                       className="photo-button">Lansdowne Pub 5/7/2010
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('3-13-2010')}
                                       className="photo-button">Hennessy's 3/13/2010
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('2-10-2010')}
                                       className="photo-button">Matterhorn 2/10/2010
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton id="dropdown-basic-button" title="2009" className="select-photos">
                        <Dropdown.Item onClick={() => setDateValue('10-31-2009')}
                                       className="photo-button">Hennessy's 10/31/2009
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('10-2-2009')}
                                       className="photo-button">Hennessy's Sept/Oct 2009
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('9-17-2009')}
                                       className="photo-button">Press Pics 2009
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('7-23-2009')}
                                       className="photo-button">Rev. Rock Bar 7/23/2009
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('7-18-2009')}
                                       className="photo-button">Rally in the Alley 7/18/2009
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDateValue('4-11-2009')}
                                       className="photo-button">Basta Bar Apr/Jun 2009
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
                {renderPhotoGallery(photos)}
                <div className="photos-loader">
                    <FontAwesomeIcon icon={faSpinner} spin pulse size="3x" />
                </div>
            </section>
            <SocialMediaFooter />
        </section>
    );
}

export default Photos;
