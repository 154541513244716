// React Imports
import React from 'react';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookSquare, faInstagram, faTwitter, faSnapchatGhost, faYoutubeSquare} from '@fortawesome/free-brands-svg-icons';
// Styling
import './social_media_news.scss';

function SocialMediaNews() {
    return (
        <div className="social-media-feeds">
            <aside className="col-md-4 col-sm-6 col-xs-12 twitter-feed-container">
                <h2 className="social-media-feed-title">
                    <a href="https://twitter.com/kilroerock" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="lg" className="news-social-media-icon"/>
                        Twitter @kilroerock
                    </a>
                </h2>
                <a className="twitter-timeline" href="https://twitter.com/kilroerock?ref_src=twsrc%5Etfw">
                Tweets by kilroerock
                </a>
                <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>
            </aside>
            <aside className="col-lg-5 col-md-6 col-sm-6 col-xs-12 instagram-feed-container">
                <h2 className="social-media-feed-title">
                    <a href="https://instagram.com/kilroerock" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="lg" className="news-social-media-icon"/>
                        Instagram @kilroerock
                    </a>
                </h2>
                <div className="instagram-feed">
                    <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"/>
                    <iframe title="instagramFeed"
                            src="https://cdn.lightwidget.com/widgets/d5ed070a6163504ba956e2c09eb1ea85.html"
                            scrolling="no"
                            allowtransparency="true"
                            className="lightwidget-widget"
                            style={{width: '100%', border: '0', overflow: 'hidden', height: '324px'}}>
                    </iframe>
                </div>
            </aside>
            <aside className="col-lg-3 col-sm-12 social-media-no-feeds">
                <h2 className="social-media-no-feeds-title">
                    <a href="https://snapchat.com/add/kilroerock" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSnapchatGhost} size="4x"/>
                    </a>
                </h2>
                <h2 className="social-media-no-feeds-title">
                    <a href="https://facebook.com/kilroerock" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookSquare} size="4x"/>
                    </a>
                </h2>
                <h2 className="social-media-no-feeds-title">
                    <a href="https://youtube.com/kilroeband" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutubeSquare} size="4x"/>
                    </a>
                </h2>
            </aside>
        </div>
    );
}

export default SocialMediaNews;
