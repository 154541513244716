// React Imports
import React from 'react';
// Components
import MusicPlayer from '../audio_player/audio_player';
// Styling
import './footer.scss';

function Footer() {
    return (
        <footer>
            <MusicPlayer />
        </footer>
    );
}

export default Footer;
