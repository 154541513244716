// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../../utils/firebase';
import _orderBy from 'lodash/orderBy';
import _take from 'lodash/take';
import Moment from 'react-moment';
// Components
import SocialMediaNews from '../../social_media/social_media_news/social_media_news';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNewspaper} from '@fortawesome/free-regular-svg-icons';
// Styling
import '../news.scss';

function HomeNews() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('news');

        ref.once('value').then(data => {
            setNews(data.val());
        });
    }, []);

    function renderNewsArticles(newsArticles) {
        return _take(_orderBy(newsArticles, 'date', ['desc']), 1).map(article => (
            <article key={article.id}>
                <h2 className="news-article-date">
                    <Moment format="M.D.YY">
                        {article.date}
                    </Moment>
                </h2>
                <h3 className="news-article-title">{article.title}</h3>
                <p>{article.article}</p>
            </article>
        ))
    }

    return (
        <section className="scrolling-bg news-bg" id="news">
            <div className="container">
                <div>
                    <h1 className="section-header">
                        <FontAwesomeIcon icon={faNewspaper} size="lg" className="section-header-icon" />News
                    </h1>
                    {renderNewsArticles(news)}
                    <button className="btn btn-main">
                        <a href="/news" className="btn-main-link">
                            <FontAwesomeIcon icon={faNewspaper} className="main-menu-icon" />
                            More News
                        </a>
                    </button>
                </div>
                <SocialMediaNews />
            </div>
        </section>
    );
}

export default HomeNews;