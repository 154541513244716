// React Imports
import React from 'react';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy'
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeadphones, faStar, faVideo, faCameraRetro, faMobileAlt} from '@fortawesome/free-solid-svg-icons'
import {faNewspaper, faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
// Styling
import './main_menu.scss';

function MainMenu() {
    return (
        <nav className="main-menu">
            <ul className="main-menu-link-list">
            <Scrollspy items={['home', 'news', 'shows', 'band', 'video', 'photos', 'booking']} currentClassName="main-scrollspy-hover">
                <li className="main-menu-link-list-item">
                    <NavLink to="/#home"
                             smooth
                             activeClassName=""
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faHeadphones} className="main-menu-icon" />Home
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/#news"
                             smooth
                             activeClassName=""
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faNewspaper} className="main-menu-icon" />News
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/#shows"
                             smooth
                             activeClassName=""
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faCalendarAlt} className="main-menu-icon" />Shows
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/#band"
                             smooth
                             activeClassName=""
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faStar} className="main-menu-icon" />Band
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/video"
                             smooth
                             activeClassName="active"
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faVideo} className="main-menu-icon" />Video
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/photos"
                             smooth
                             activeClassName="active"
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faCameraRetro} className="main-menu-icon" />Photos
                    </NavLink>
                </li>
                <li className="main-menu-link-list-item">
                    <NavLink to="/#booking"
                             smooth
                             activeClassName=""
                             className="ga-home main-menu-link">
                        <FontAwesomeIcon icon={faMobileAlt} className="main-menu-icon" />Booking
                    </NavLink>
                </li>
            </Scrollspy>
            </ul>
        </nav>
    );
}

export default MainMenu;
