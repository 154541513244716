// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import _orderBy from 'lodash/orderBy';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons'
// Styling
import './booking.scss';

function Booking() {
    const [catalog, setCatalog] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('catalog');

        ref.once('value').then(data => {
            setCatalog(data.val());
        });
    }, []);

    function renderSongCatalog(songs) {
        return _orderBy(songs, 'song').map(song => (
            <ul key={song.id}>
                <li className="song-list-item">{song.song}</li>
            </ul>
        ))
    }

    return (
        <section className="scrolling-bg booking-bg" id="booking">
            <article className="container">
                <h1 className="section-header">
                    <FontAwesomeIcon icon={faMobileAlt} size="lg" className="section-header-icon" />Booking
                </h1>
                <p>Interested in booking Kilroe? Don't wait. Turn your club, bar, school or private party into a killer
                    rock show. See the full song catalog below.</p>
                <p className="booking-info">
                    <a href="mailto:booking@kilroerock.com" className="booking-info-link">booking@kilroerock.com</a>
                    <span className="booking-info-phone">617.276.2570</span>
                </p>
                <div className="song-list">
                {renderSongCatalog(catalog)}
                </div>
            </article>
        </section>
    );
}

export default Booking;
