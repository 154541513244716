// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import AudioPlayer from 'react-h5-audio-player';
// Styling
import 'react-h5-audio-player/src/styles.scss';
import './audio_player.scss';

function MusicPlayer() {
    const [songs, setSongs] = useState([0]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('songs');

        ref.once('value').then(data => {
            setSongs(data.val());
        });
    }, []);

    const [currentMusicIndex, setMusicIndex] = useState(0);

    function handleClickPrevious() {
        setMusicIndex(currentMusicIndex === 0 ? songs.length - 1 : currentMusicIndex - 1)
    }

    function handleClickNext() {
        setMusicIndex(currentMusicIndex < songs.length - 1 ? currentMusicIndex + 1 : 0)
    }

    function renderSongs(songs) {
        return (
            <>
                <AudioPlayer
                    showSkipControls={true}
                    showJumpControls={false}
                    showLoopControl={false}
                    header={songs[currentMusicIndex].name}
                    onClickPrevious={handleClickPrevious}
                    onClickNext={handleClickNext}
                    onEnded={handleClickNext}
                    src={songs[currentMusicIndex].src} />
            </>
        )
    }

    return (
        <>
            {renderSongs(songs)}
        </>
    );
}

export default MusicPlayer;
