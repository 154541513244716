// React Imports
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// Components
import Header from './components/header/header';
import News from './components/news/news';
import Photos from './components/photos/photos';
import Video from './components/video/video';
import Containers from './components/containers/containers';
import Footer from './components/footer/footer';
import ScrollToTop from './components/scroll_to_top/scroll_to_top';
// Styling
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/kilroe.scss';

function Kilroe() {
  return (
    <>
        <BrowserRouter>
            <Header />
            <Switch>
                <Route path="/" component={Containers} exact />
                <Route path="/news" component={News} exact />
                <Route path="/photos" component={Photos} exact />
                <Route path="/video" component={Video} exact />
                <Route component={Error}/>
            </Switch>
            <ScrollToTop />
        </BrowserRouter>
        <Footer />
    </>
  );
}

export default Kilroe;
