// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import _orderBy from 'lodash/orderBy';
import Moment from 'react-moment';
//Components
import SocialMediaFooter from '../social_media/social_media_footer/social_media_footer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faNewspaper} from '@fortawesome/free-regular-svg-icons';
// Styling
import './news.scss';

function News() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('news');

        ref.once('value').then(data => {
            setNews(data.val());
        });
    }, []);

    function renderNewsArticles(newsArticles) {
        return _orderBy(newsArticles, 'date', ['desc']).slice(1).map(article => (
            <article key={article.id} className="news-article">
                <h2 className="news-article-date">
                    <Moment format="M.D.YY">
                        {article.date}
                    </Moment>
                </h2>
                <h3 className="news-article-title">{article.title}</h3>
                <p>{article.article}</p>
            </article>)
        )
    }

    return (
        <div>
            <section className="news-container">
                <div className="article-container">
                    <h1 className="section-header">
                        <FontAwesomeIcon icon={faNewspaper} size="lg" className="section-header-icon" />Old News
                    </h1>
                    {renderNewsArticles(news)}
                </div>
            </section>
            <SocialMediaFooter />
        </div>
    );
}

export default News;
