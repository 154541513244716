// React Imports
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyDniD5-6CXELq8yiur_Q-IfisvZzdujnnA',
    authDomain: 'kilroe-react.firebaseapp.com',
    databaseURL: 'https://kilroe-react.firebaseio.com',
    projectId: 'kilroe-react',
    storageBucket: 'kilroe-react.appspot.com',
    messagingSenderId: '597149570325',
    appId: '1:597149570325:web:e56f3857dd3a193b2a051c',
    measurementId: 'G-PZMGBM53DR'
};

firebase.initializeApp(firebaseConfig);
