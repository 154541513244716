// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../utils/firebase';
import _orderBy from 'lodash/orderBy';
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
// Styling
import './band.scss';

function Band() {
    const [band, setBand] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('band');

        ref.once('value').then(data => {
                setBand(data.val());
            });
    }, []);

    function renderBandMembers(bandMembers) {
        return _orderBy(bandMembers, 'lastName').map(member => (
            <div key={member.id} className="col band-member-container">
                <img src={member.image} alt={member.firstName + ' ' + member.lastName} className="band-member-image"/>
                <p className="band-member-name">{member.firstName} {member.lastName}</p>
                <p className="band-member-title">{member.title}</p>
            </div>
        ))
    }

    return (
        <section className="scrolling-bg band-bg" id="band">
            <article className="container">
                <h1 className="section-header">
                    <FontAwesomeIcon icon={faStar} size="lg" className="section-header-icon" />The Band
                </h1>
                <p>Blink 182, Third Eye Blind, Green Day, Foo Fighters. Kilroe is here to hit the stage and dish out all
                    of the best rock songs from the 90's and early 2000's, mixed with a few classics and lots of
                    surprises. You haven't experienced a live show in a local venue like this before as Kilroe energizes
                    crowds from start to finish with their full-on arena rock attitude. The members of Kilroe (Kris
                    Bishop, Rob O'Neill, Mark Donatelli, Jeremy Rosenberger) have spent over ten years playing in
                    successful original bands and decided to take their talent and experience to the Boston area and
                    northeast, turning themselves into one of New England's hottest cover bands.</p>
                <p>You've seen Kilroe in and around Boston at Bell In Hand, Burren Bar, Harpoonfest, Hennessy's,
                    Lansdowne Pub, Whiskey Priest, Green Dragon, and Paddy O's. Kilroe has also become a fixture at
                    other New England venues such as the Matterhorn at Sunday River and One Pelham East in Newport, RI.
                    Tired of those same old cover bands that just go through the motions on stage? Kilroe puts on an
                    incredible live show every time out that you'll be telling your friends about the next day.</p>
            </article>
            <article>
                <div className="container">
                    <div className="band-container">
                    {renderBandMembers(band)}
                    </div>
                </div>
            </article>
        </section>
    );
}

export default Band;
