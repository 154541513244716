// React Imports
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import '../../../utils/firebase'
// Fonts
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faTwitter, faSnapchatGhost, faYoutube} from '@fortawesome/free-brands-svg-icons';
// Styling
import './social_media_footer.scss';

function SocialMediaFooter() {
    const [socialMediaIcons, setSocialMediaIcons] = useState([]);

    useEffect(() => {
        const database = firebase.database();
        const ref = database.ref('socialMediaIcons');

        ref.once('value').then(data => {
            setSocialMediaIcons(data.val());
        });
    }, []);

    const footerIcons = {faFacebookF, faInstagram, faTwitter, faSnapchatGhost, faYoutube};

    function renderSocialMediaIcons(socialMediaIcons) {
        return socialMediaIcons.map(icon => (
            <div key={icon.id} className="social-media-footer-icon-container">
                <a className="social-media-footer-icon"
                   href={'https://' + icon.app + '.com/' + icon.userName}
                   target="_blank" rel="noopener noreferrer">
                   <FontAwesomeIcon icon={footerIcons[icon.icon]} size="2x" />
               </a>
            </div>
        ))
    }

    return (
        <section className="scrolling-bg social-media-icons-bg">
            <div className="container social-media-footer">
                {renderSocialMediaIcons(socialMediaIcons)}
            </div>
        </section>
    );
}

export default SocialMediaFooter;
